<div class="row">
    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="stats-card-box">
            <div class="icon-box">
                <i class='bx bx-map-alt'></i>
            </div>
            <span class="sub-title">No. of Booking (Today)</span>
            <h3>{{counter?.today}}</h3>
        </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="stats-card-box">
            <div class="icon-box">
                <i class='bx bx-line-chart'></i>
            </div>
            <span class="sub-title">No. of Booking (Total)</span>
            <h3>{{counter?.total}}</h3>
        </div>
    </div>

    <!-- <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="stats-card-box">
            <div class="icon-box">
                <i class='bx bx-star'></i>
            </div>
            <span class="sub-title">Total Reviews</span>
            <h3>99</h3>
        </div>
    </div> -->

    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="stats-card-box">
            <div class="icon-box">
                <i class='bx bx-heart'></i>
            </div>
            <span class="sub-title">Total Downline</span>
            <h3>{{counter?.downline}}</h3>
        </div>
    </div>
</div>