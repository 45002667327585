<div class="container">
    <div class="row" style="height: 100vh; align-items: center;">
       <div class="col-md-6 mx-auto mt-5">
          <div class="payment">
             <div class="payment_header">
                <div class="check"><i class="fa fa-close" aria-hidden="true"></i></div>
             </div>
             <div class="content">
                <h1>Payment Failed !</h1>
                <p style="padding : 5px">Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. </p>
                <a href="#">Go to Home</a>
             </div>
          </div>
       </div>
    </div>
 </div> 