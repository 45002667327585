<section class="footer_section">
    <div class="top_strip container">
        <div class="row m-0">
            <div class="col-md-6 col-12">
                <p class="address">Address : <br> B/21, Niyamat Building,<br> Panchsheel, Ajmer <br> India</p>
            </div>
            <div class="col-md-6 col-12 d-sm-flex justify-content-end">
                <div class="">
                    <p class="login_btn" (click)="displayModal = true">Terms & Condition</p>
                    <p class="login_btn" (click)="displayModal1 = true">Privacy Policy</p>
                    <p class="login_btn" (click)="displayModal2 = true">Payment Option</p>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-12">
                <p style="color: white;">© Copyright. Adorn Tourism All Rights Reserved.</p>
            </div>
        </div>
        <!-- <div class="btn_div d-flex align-items-center">
            
            <select class="login_btn" name="cars" id="cars" (change)="openModal($event)">
                <option value="terms" selected disabled>Policies</option>
                <option value="1">Terms & Condition</option>
                <option value="2">Privacy Policy</option>
                <option value="3">Payment Option</option>
              </select>
        </div> -->
    </div>
</section>


<p-dialog header="Terms & Condition" [breakpoints]="{'960px': '75vw'}" [style]="{width: '50vw'}" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <ul>
        <!-- <li style="margin-bottom: 10px;">Check-in : 15 April 2023</li> -->
        <li>All correspondence(s) in respect of Tours / Travel Services and bookings should be addressed to Adorn Tourism Private Limited</li>
        <li>You / Client means the Person / Persons in whose name or on whose behalf the booking is made.</li>
        <li>We / Us / Company means Adorn Tourism Private Limited</li>
        <li>On confirmation of booking your contract is with Adorn Tourism Private Limited.</li>
        <li>For confirming any package all accurate details are required.</li>
        <li>All booking must be made on pre-payment basis (i.e. must be paid in full in India before the passengers departure & in foreign currency as indicated in the manual & adhering to the RBI guidelines/rules/circulars issued by the concerned authorities & also include provisions of FEMA 1999.</li>
        <li>Payments in INR/Foreign currency must be accompanied by the original passport & tickets of all the passengers traveling.</li>
        <li>All rates are subject to a revision if there is a fluctuation in the currency rates.</li>
        <li>All rates, terms & conditions are subject to change without notice.</li>
        <li>GST and 5% TSC will Applicable on Each Booking.</li>
        <li>There could be variation of rates for the booking/reservations made by the hotel management during the peak season/ trade fair/New Year/ Christmas Eve, Public holidays etc.</li>
        <li>For any modifications, users have to pay applicable cancellation/modification charges. Modified bookings will be subject to availability and may depend on the booking policy of the hotel. </li>
        <li>The cancellation/modification charges are standard and any waiver is on the discretion of hotel.</li>
        <li>We accept almost all major credit cards, such as Visa, MasterCard, American Express, Discover, and Diners . Full payment must be done by credit card, and guests are entailed to provide their credit card number to complete the transaction. This, in turn, will be showed as a charge on your statement.</li>
        <li>Once the payment is done, our travel consultants will send you a confirmation slip by e-mail . Its print can be produced as an evidence of payment to the service provider to redeem your tour package. But make sure that you´ve provided correct information pertaining to your travel requirements at the time of booking.</li>
        <li style="list-style: none;">Accommodation and Transport</li>
            <li>Hotels/Room.  Accommodation provided is generally on a twin sharing bases so single occupancy, arising for whatever reason, will cost more. Extra bed will also be charges as per hotel specified rates. Guests will have to abide by the check-in/out time of the respective hotels. Early arrivals/late departures or overstays due for whatever reason is the responsibility of the guests themselves. Use of hotel services and amenities are the responsibility of the guests.</li>
            <li>Meals. There is a pre-set menu for meals as per the tour program. We reserve the right to change the meal arrangements if circumstances demand us to do so.  A tour participant missing a meal for whatever reason can not the meal missed.</li>
            <li>Transport. Our cars are allotted on the booking priority basis. Each transport operators have their own set of rules that must be complied. The company is not liable to any dispute arising from non-compliance of such rules. Any damages caused by you during the travel shall be payable by you.</li>
            <li>Safety and Health Matters. All baggage and personal effects shall be at all times and in all circumstances, the responsibility of passengers. Adorn Tourism Pvt. Ltd. is not responsible for any type of loss and damage (baggage or person). Health matters should be the sole responsibility of the traveler. Adequate travel insurance cover is strongly recommended. It shall be duty of the guest to inform the Company of any medical condition(s) that may affect his ability to undertake the activities or could negatively affect the interest of the group. Under such situation, the Company reserves the right to deny the guest participation in some of the activities with no refund.</li>
            <li style="list-style: none;">Itinerary Changes</li>
            <li>Sometimes we may make amendments to the itinerary, due to unforeseen developments such as unavailability of sights, adverse climatic conditions etc.  In some cases such changes could mean extra charges but you will be informed of the complete details. In the event of the Company exercising its rights to amend any aspect of the services, the guest(s) shall have to continue with the program as amended.</li>
            <li style="list-style: none;">Payments</li>
            <li>The amount paid at the time of booking is non-refundable. We do not accept any form of cheques. The bookings will be processed only after 25% of the total amount has been paid. The full amount must be paid fifteen days before the tour/stay starts. We will send a reminder of full payment 20 days before the tour/stay starts. Failure to deliver complete payment will result in cancellation of the arrangement. For instant bookings or on-the-spot booking, 100% payment is required before bookings are processed.</li>
            <li style="list-style: none;">Travel Documents</li>
            <li>It is the sole responsibility of the guest(s) to collect in time and hold valid travel documents and statutory clearances, such as passports, visas, confirmed air/rail tickets, insurance and medical insurance certificates, any other document used to confirm an arrangement with service provider and other statutory certificates, to enable him/her to travel on tour.  It is the guest(s) responsibility to collect in time, the necessary travel documents or other documents from the booking office. Essential travel documents must be in the hands of the Owner at all times, and be ready in case of checks by local authorities.</li>
            <li>Personal documents are non-transferable. The company will not accept request for help in replacing lost documents. If in the course of the tour, a guest(s) is refused entry by authorities due to missing personal document, the guest can not claim compensation from the company.</li>
            <li style="list-style: none;">OUR LIABILITIES & LIMITATIONS</li>
            <li>Adorn Tourism Pvt. Ltd. rates are based on the prevailing rates as negotiated by us with the hotels, Transporter  etc. Hotels and Transporter retain the right to modify the rates without notice. In case of such change - the rates quoted by us before modification can be changed by us according to the modifications by hotels or transporter</li>
            <li>Adorn Tourism Pvt. Ltd. itineraries are sample itineraries, intended to give you a general idea of the likely trip schedule. Numerous factors such as weather, road conditions, the physical ability of the participants etc. may dictate itinerary changes either before the tour or while on the trail. Adorn Tourism Pvt. Ltd. reserves the right to amend any aspect of the itinerary including transportation and accommodation without notice in the interest of the trip, participants' safety, comfort and general well being without making any rebate, allowance or refund and extra cost, if any, would be payable by the client.</li>
            <li>Adorn Tourism Pvt. Ltd. shall not be responsible for any delay or change in programme or expenses incurred or special / consequential loss, injury and damage - directly or indirectly - due to natural hazards, flight cancellations, accident, breakdown of machinery or equipments, breakdown of transport, weather, sickness, landslides, political closures, acts of God, perils incident to the sea, floods, fire, acts of Government or any other authorities, wars, civil disturbances, riots, theft, pilferage, epidemics, quarantines, medical or custom department regulations, defaults, or any other causes beyond our control or any untoward incidents or any such causes whatsoever including any liability or extra expenses sustained by the Clients. Further, the Clients shall be liable for the liability or extra expenses incurred by him / her as a result of the forgoing causes.</li>
            <li>Adorn Tourism Pvt. Ltd. shall not be responsible and shall not accept any liability towards the Clients or his/her legal representative for any loss of property or damages resulting from death or injuries including loss of services which the Clients may sustain on account of act, negligence or default by any transportation Company, hotel agents or any other body of persons, its agents or service providers providing such services or facilities or arising out of or while engaged in any tour, means of transportation or other services, whether due to ownership, maintenance , use, operation or control of any automobile, bicycle, boat, motor, or other vehicle in common carrier or otherwise and whether due to its or there negligence or otherwise or regardless of how caused. Clients using sporting equipment, bicycles, sailing boats, scuba diving gear, hired or supplied by hotels or rental bodies shall do so at their own risk.</li>
            <li>Adorn Tourism Pvt. Ltd. do not have any insurance policy covering the expenses for accident, sickness, loss due to theft, or any other reasons. Visitors are advised to seek such insurance arrangements in their home country. All baggage & personal property/s at all times are at the client's risk.</li>
            <li>Adorn Tourism Pvt. Ltd. reserves the right to cancel any services in case it deems that the tour, trip or passage is impracticable for any reason or should circumstances warrants such action, upon refunding the value thereof and the Client shall not have any other or further claim against the company by any reason thereof.</li>
            <li>Adorn Tourism Pvt. Ltd. reserves the right to refuse to carry out its contract with any person whom it may consider to be undesirable in its absolute discretion and it shall not be required to show any reason for doing so.All disputes arising in connection with this Agreement shall be referred to an Arbitrator to be appointed by Adorn Tourism Pvt. Ltd. All such disputes shall be subject to the jurisdiction of Delhi (India) Courts only. The laws of India shall govern the validity, interpretation, construction, performance and enforcement of these terms and conditions.</li>
            <li>Guest’s acceptance and/or signature on the booking form shall mean complete acceptance of the ‘Terms and Conditions’ contained herein by the guest(s). In case of one person is acting on behalf of the other members of the Group signing the ‘I ACCEPT’, it shall be deemed that the others have duly authorized the concerned signing person. The signing person has to write down the names of the members under his name.</li>
        </ul>
        <ng-template pTemplate="footer">
        <!-- <p-button icon="pi pi-check" (click)="displayModal=false" label="Ok" styleClass="p-button-text"></p-button> -->
        </ng-template>
</p-dialog>
<p-dialog header="Privacy Policy" [breakpoints]="{'960px': '75vw'}" [style]="{width: '50vw'}" [(visible)]="displayModal1" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <ul>
        <li style="margin-bottom: 10px;">Adorn Tourism Pvt Ltd , including all legal entities , respects your (customers) (hereafter referred as “you” or “user”) privacy and values our relationship with our business partners, clients, third party who have contractual relationship with us (hereafter referred as “third party” or “service provider”) and any party who purchase / intend to purchase / inquire about any product(s) and / or service(s) made available by us through any of our customer interface channels including website, mobile site, mobile app & offline channels including call centers and offices (collectively referred herein as "Sales Channels"). </li>
        <li>We are committed to protecting your personally identifiable information by which you can be identified (hereafter referred as “information” or “personal information” or “personal data” or “special category of personal data”) by handling it responsibly and safeguarding it using appropriate technical and organizational measures.</li>
        <!-- <li>Rates are per person based on 5 Nights accommodation on twin / triple sharing basis.</li> -->
        <li>We would like to assure you that we follow appropriate standards when it comes to protecting your privacy on our Sales Channel. This policy outlines the types of personal information collected on our Website, how this data is used/processed and safeguarded as well as how users may exercise their rights with respect to personal information.</li>
        <li>This Privacy Policy applies to adorntourism.com and services (“Services”), including off-site Services, such as our email services, customer care and support services and the “Contact us”; “Apply with us” and “Share on” plugins on our digital properties.</li>
        <li>By using or accessing the Website or other Sales Channels, the user hereby agrees with the terms of this Privacy Policy and the contents here in.</li>
        <li>This Privacy Policy does not apply to any website(s), mobile sites and mobile apps of third parties, even if their websites/products are linked to our Website. User should take note that information and privacy practices of Yatra’ s business partners, advertisers, sponsors or other sites to which we provides hyperlink(s), may be materially different from this Privacy Policy. Accordingly, it is recommended that you review the privacy statements and policies of any such third parties.</li>
    </ul>
        <ng-template pTemplate="footer">
        <!-- <p-button icon="pi pi-check" (click)="displayModal=false" label="Ok" styleClass="p-button-text"></p-button> -->
        </ng-template>
</p-dialog>
<p-dialog header="Payment Option" [breakpoints]="{'960px': '75vw'}" [style]="{width: '50vw'}" [(visible)]="displayModal2" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <ul>
        <li style="margin-bottom: 10px;">Internet Banking</li><li style="list-style: none;">
            Visa, Master and American Express Card payments are processed through an online payment gateway system. You need not worry about your card information falling into the wrong hands because your bank will authorize the card transaction directly without any information passing through us. In approximately 25-30 seconds (depending on your internet connection) your bank will issue, using the online payment gateway, an authorization code and confirmation of completion of transaction.
            You can book your product using SSL encryption (the internet standard for secure transactions). In fact, transacting online with a credit card at the website is even safer than using a credit card at a restaurant because we do not retain your credit card information.
            If the payment on the credit card is declined for some reason, alternate payment instructions must be received 72 hours prior to the time of departure; else, the order is liable to be cancelled.</li>
        <li style="margin-bottom: 10px;">Online Card Payments</li><li style="list-style: none;">
            If you have an account with any of the below mentioned banks, then you can pay for your order through the respective bank's net banking options and the amount will be automatically debited from your account. we processes payments through an online gateway system which enables safe and secure transactions.
Transaction Confirmation
You should not take any action based on information on the Website until you have received a confirmation of your transaction. In case of confirmations to be received by email, if you do not receive a confirmation of your purchase/transaction within the stipulated time period, first look into your "spam" or "junk" folder to verify that it has not been misdirected, and if still not found, please contact us.</li>
        
    </ul>
        <ng-template pTemplate="footer">
        <!-- <p-button icon="pi pi-check" (click)="displayModal=false" label="Ok" styleClass="p-button-text"></p-button> -->
        </ng-template>
</p-dialog>