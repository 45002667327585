<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="vesax-responsive-nav">
        <div class="vesax-responsive-menu">
            <div class="responsive-burger-menu d-lg-none d-block">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
        </div>
    </div>
    <div class="vesax-nav">
        <nav class="navbar navbar-expand-md navbar-light">
            <div class="collapse navbar-collapse">
                <div class="others-option d-flex align-items-center">
                    <div class="option-item">
                        <div class="dropdown profile-nav-item">
                            <a style="text-decoration: none!important;" class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="menu-profile">
                                    <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    <span class="name" style="text-decoration: none;">{{this.userProfile.userName}}</span>
                                </div>
                            </a>
                            <div class="dropdown-menu">
                                <div class="dropdown-header d-flex flex-column align-items-center">
                                    <div class="figure mb-3">
                                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    </div>

                                    <div class="info text-center">
                                        <!-- <span class="name">{{this.userProfile.userName}}</span>
                                        <p class="mb-3 email">{{this.userProfile.loginName}}</p> -->
                                    </div>
                                </div>
                                <div class="dropdown-body">
                                    <ul class="profile-nav p-0 pt-3">
                                        <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
                                    </ul>
                                </div>
                                <div class="dropdown-footer">
                                    <ul class="profile-nav">
                                        <li class="nav-item">
                                            <a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span>Logout</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="option-item">
                        <a routerLink="/dashboard-add-listings" class="default-btn"><i class="flaticon-more"></i> Add Listing</a>
                    </div> -->
                </div>
            </div>
        </nav>
    </div>
    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="others-option">
                    <div class="option-item">
                        <div class="dropdown profile-nav-item">
                            <a class="dropdown-toggle" style="text-decoration: none;" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="menu-profile">
                                    <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    <!-- <span class="name">{{this.userProfile.userName}}</span> -->
                                </div>
                            </a>
                            <div class="dropdown-menu">
                                <div class="dropdown-header d-flex flex-column align-items-center">
                                    <div class="figure mb-3">
                                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="info text-center">
                                        <!-- <span class="name">{{this.userProfile.userName}}</span>
                                        <p class="mb-3 email">{{this.userProfile.loginName}}</p> -->
                                    </div>
                                </div>
                                <div class="dropdown-body">
                                    <ul class="profile-nav p-0 pt-3">
                                        <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
                                    </ul>
                                </div>
                                <div class="dropdown-footer">
                                    <ul class="profile-nav">
                                        <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span>Logout</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="container">
                <div class="">
                    <div class="others-option">
                        <div class="option-item">
                        
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- End Navbar Area -->