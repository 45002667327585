<!-- Start Sidemenu Area -->
<div class="sidemenu-area">
    <div class="sidemenu-header">
        <a routerLink="/dashboard" class="navbar-brand d-flex
            align-items-center">
            <img src="../../../../assets/img/SJ_logo__2_-removebg-preview.png" alt="image">
        </a>
        <div class="responsive-burger-menu d-block d-lg-none">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
    </div>
    <div class="sidemenu-body">
        <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
            <li class="nav-item-title">
                Main
            </li>

            <!-- sidebar for supplier -->
            <!-- <ng-container *ngIf="roleName == ''"> -->
                <li class="nav-item" routerLinkActive="active" routerLinkActive="active mm-active">
                    <a routerLink="/dashboard" class="nav-link">
                        <span class="icon"><i class='bx bx-wallet'></i></span>
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="active" routerLinkActive="active mm-active">
                    <a routerLink="/registered-user" class="nav-link">
                        <span class="icon"><i class='bx bx-wallet'></i></span>
                        <span class="menu-title">Registered User</span>
                    </a>
                </li>

                <li class="nav-item" routerLinkActive="active" routerLinkActive="active mm-active">
                    <a href="javascript:void(0)" class="collapsed-nav-link nav-link">
                        <span class="icon"><i class='bx bx-layer'></i></span>
                        <span class="menu-title">Master</span>
                    </a>
                    <ul class="sidemenu-nav-second-level">
                        <li class="nav-item" routerLinkActive="active" *ngIf="roleName == 'Admin@gmail.com'" routerLinkActive="active mm-active">
                            <a routerLink="/productMaster" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Item Master</span>
                            </a>
                        </li>
                        <li class="nav-item" routerLinkActive="active" routerLinkActive="active mm-active">
                            <a routerLink="/billGenerate" class="nav-link">
                                <span class="icon"><i class='bx bx-wallet'></i></span>
                                <span class="menu-title">Bill Generate Master</span>
                            </a>
                        </li>
                    </ul>
                </li>
            <!-- </ng-container> -->

            <li class="nav-item" routerLinkActive="active" routerLinkActive="active mm-active">
                <a (click)="logOut()" class="nav-link" style="cursor: pointer;">
                    <span class="icon"><i class='bx bx-log-out'></i></span>
                    <span class="menu-title">Logout</span>
                </a>
            </li>

        </ul>
    </div>
</div>
<!-- End Sidemenu Area -->