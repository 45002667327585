<!-- Start Navbar Area -->
<!-- Header Start -->
<!-- <section class="top_header">
    <div class="top_strip">
        <div class="contact_info">
            <p class="contact_number">24X7, +91-7976881807 / ops@adorntourism.com</p>
        </div>
    </div>
</section> -->
<p-toast
  [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center'}}">
</p-toast>
<div class="header-section">
    <div class="vesax-nav" style="background: white;">
        <div class="containers">
            <nav class="navbar navbar-expand-md navbar-light">
                <img src="assets/img/banner.jpeg" routerLink="/" alt="" style="padding: 5px;cursor: pointer;">

                <div class=" justify-content-end" style="display: block!important;">

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item book_event">
                            <span (click)="bookEvent()"><i class="bx bx-pencil" style="padding-right: 5px;"></i>Book Event</span>
                        </div>
                        <div class="option-item" *ngIf="userId == ''">
                            <span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i class="flaticon-user"></i> Login</span>
                        </div>
                        <div class="option-item" *ngIf="userId != ''">
                            <span (click)="logOut()"><i class="flaticon-user"></i> Logout</span>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Start Login & Register Modal -->
<div class="modal fade loginRegisterModal" id="loginRegisterModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><i class='bx bx-x'></i></button>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <button class="nav-link active" id="login-tab" data-bs-toggle="tab" data-bs-target="#login" type="button" role="tab" aria-controls="login" aria-selected="true">Login</button>
                </li>
                <!-- <li class="nav-item">
                    <button class="nav-link" id="register-tab" data-bs-toggle="tab" data-bs-target="#register" type="button" role="tab" aria-controls="register" aria-selected="true">Register</button>
                </li> -->
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="login" role="tabpanel">
                    <div class="vesax-login">
                        <form #login="ngForm" [formGroup]="loginForm" (ngSubmit)="loginFunction(login)">
                            <div class="form-group">
                                <label for=""><b>Username</b></label>
                                <input type="text"  [class.is-invalid]="login.submitted && loginForm.get('loginName').invalid" formControlName="loginName" placeholder="Enter Username" class="form-control">
                                <div class="invalid-feedback">Please Enter Username</div>
                            </div>
                            <div class="form-group">
                                <label for=""><b>Password</b></label>
                                <input type="password"  [class.is-invalid]="login.submitted && loginForm.get('password').invalid" formControlName="password" placeholder="Enter Password" class="form-control">
                                <div class="invalid-feedback">Please Enter Password</div>
                                <!-- <input type="password" [(ngModel)]="password" #userpsassword="ngModel" name="password" placeholder="Enter Password" class="form-control"> -->
                            </div>
                            <button type="submit" data-bs-dismiss="modal">Login Now</button> 
                        </form>
                        <!-- <span class="dont-account">Don't have an account? <a href="#">Register Now</a></span> -->
                    </div>
                </div>
                <div class="tab-pane fade" id="register" role="tabpanel">
                    <div class="vesax-register">
                        <div class="register-with-account">
                            <span>Register with</span>
                            <ul>
                                <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                                <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                            </ul>
                        </div>
                        <span class="sub-title"><span>Or Register with</span></span>
                        <form>
                            <div class="form-group">
                                <input type="text" placeholder="Username" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="email" placeholder="Email" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Password" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Confirm Password" class="form-control">
                            </div>
                            <button type="submit">Register Now</button>
                        </form>
                        <span class="already-account">Already have an account? <a href="#">Login Now</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login & Register Modal -->

