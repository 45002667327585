<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-navbar-style-one></app-navbar-style-one>

    <!-- {{data.images | json}} -->
    <div class="container">
		<div class="card">
			<div class="container-fliud">
				<div class="wrapper row">
					<div class="preview col-md-6">
                        <p-galleria [value]="data[0].images.data" [numVisible]="5" [showItemNavigators]="true" [showThumbnails]="false">
                            <ng-template pTemplate="item" let-item>
                                <img class="preview-image" [src]="item.previewImageSrc" [alt]="item.alt" />
                            </ng-template>
                            <ng-template pTemplate="thumbnail" let-item>
                                <div class="grid grid-nogutter justify-content-center">
                                    <img class="thumbnails" [src]="item.thumbnailImageSrc" />
                                </div>
                            </ng-template>
                        </p-galleria>  						
					</div>
					<div class="details col-md-6">
						<div class="div">
                            <p class="product-title1 title m-0">{{data[0]?.name}}</p>
                            <!-- <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis unde quibusdam, quis officia voluptatum architecto, temporibus recusandae repellat vel voluptatem neque ipsum earum illum. Quis cumque atque vel enim est?</span<>/li> -->
                            <div class=" py-2">
                                <p class="m-0">{{data[0]?.name}} | Valid Till : March 31, 2023</p>
                                <p>{{data[0].night}}  | {{data[0].day}} </p>
                            </div>
                            <h3 class="product-title">INR {{data[0]?.price | currency:'INR'}}</h3>
                            <div class="add-listings-btn">
                                <button class="submit_btn" (click)="routeToForm(data)">Book Now</button>
                            </div>
                            <p (click)="displayModal = true" style="text-decoration: underline;cursor: pointer;">Terms & Condition</p>
                        </div>
					</div>
				</div>
			</div>
		</div>

        <div class="card card-details">
            <p-tabView>
                <p-tabPanel header="Inclusion">
                    <ul>
                        <li *ngFor="let item of data[0].inclusion">
                            {{item?.li}}
                        </li>
                    </ul>
                </p-tabPanel>
            </p-tabView>
        </div>
        
	</div>



    <!-- <div class="flex-grow-1"></div> -->
    <!-- <app-copyrights class="mx-3"></app-copyrights> -->  
</div>


<p-dialog header="Terms & Condition" [breakpoints]="{'960px': '75vw'}" [style]="{width: '50vw'}" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <ul>


<li style="list-style:none;">        Terms & Condition </li>
<li> Air fare & Visa Cost not Included </li>
<li> Rates are per person based on double sharing basis. </li>
<li> RATE IS SUBJECT TO CHANGE WITHOUT PRIOR NOTICE AND NOT APPLICABLE FOR ANY SURCHARGE DATES. </li>
<li> Airport Pickup and Drop - Private Basis </li>
<li> All Sightseeing and Tour - Sharing Basis (Car,Van & Coach) </li>
<li> Hotel Checkin Time :- 02:00 pm </li>
<li> Hotel Checkout Time :- 12:00 pm </li>
<li> This is only a quotation and No hotels are book. Rate quoted will be only valid if no. of pax remain same as above. Rate and Room will be subject to availability at the time of Confirmation. Reservation only upon confirmation. The bedding configuration(DBL/TWIN) depends on hotel’s discretion and subject to availability at the time of Check-In. </li>
<li> Rates excludes credit card Merchant fee. </li>
<li> Rates are fully non refundable and non-changeable </li>
<li> Dates are non-amendable </li>
<li> Full payment must be cleared before check in </li>
<li> Hotel Name & Details will be shared, one week prior to the booking </li>
<li> Any Loss or Damage in hotel premises penalty will be pay to the hotel directly </li>
<li> Any additional services which is not complimentary such as Extra internet usage, extra water bottles, room services, Mini Bar etc. will be paid directly to Hotel </li>
<li> Smoking is prohibited in all hotels... if hotel found someone guilty he/she need to pay penalty. </li>
<li> If you make a booking and money is deducted, you should wait for 24 - 48hrs and if you do not receive email confirmation till then, please email us support@adorntourism.com mentioning the following details: - </li>

<li style="list-style:none;"> ID Used for booking </li>
<li style="list-style:none;"> Name for whom Booking was done / </li>
<li style="list-style:none;"> Date of Booking/ </li>
<li style="list-style:none;"> Time of Booking/ </li>
<li style="list-style:none;"> Amount deducted </li>

    </ul>
        <ng-template pTemplate="footer">
        <!-- <p-button icon="pi pi-check" (click)="displayModal=false" label="Ok" styleClass="p-button-text"></p-button> -->
        </ng-template>
</p-dialog>